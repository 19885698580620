import React from "react";
import { Link } from "gatsby";
import * as styles from "./index.module.css";
import { withAuthentication } from "../../hoc/withAuthentication";
import Main from "../../components/main";
import Statistics from "../../components/statistics";
import Banner from "../../components/banner";
import ContentArea from "../../components/contentArea";
import MasonryTiles from "../../components/masonryTiles";
import FilterBar from "../../components/filterBar";
import Button from "../../components/button";
import NavPanel from "../../components/navPanel";
import ValueRow from "../../components/valueRow";
import DisplayName from "../../components/displayname";
import GridContainer from "../../components/gridContainer";
import Consts from "../../config/consts";
import Api from "../../config/api";
import util from "../../config/util";
import bgroovia_background from "../../images/bgroovia_background_image.jpg";
import bgroovia_background_tile from "../../images/bgroovia_tile_background.jpg";
import bgroovia_logo from "../../images/bgroovia_logo_white.png";
import bgroovia_text from "../../images/bgroovia_logo_text.png";
import gradient_background from "../../images/bgroovia_gradiant_background.png";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class BGroovia extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            this.trending_section_ref = React.createRef();
            this.explore_section_ref = React.createRef();
            this.artists_section_ref = React.createRef();
            const params = new URLSearchParams(props.location.search);
            const param_genre = params.get("genre");
            this.state = {
                darkMode: true,
                windowSize: window.innerWidth,
                user: props.auth.user || null,
                selectedGenres: param_genre ? [param_genre] : [],
                selectedSortBy: "RECENTLY_ADDED",
                selectedPriceMin: -1,
                selectedPriceMax: -1,
                selectedListingStates: ["FIXED_PRICE_SALE", "AUCTION_SALE", "NOT_FOR_SALE"],
                artists: [],
            };
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            // Make the call to grab the featured artists
            Api.market({
                endpoint: "/content/featured/profiles",
                method: "GET",
                data: {
                    scheme: "BGROOVIA",
                },
            })
                .then((profiles) => {
                    this.setState(
                        {
                            initialising: false,
                            artists: profiles.data,
                        },
                        () => {}
                    );
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                        artist: null,
                    });
                });
        }

        onShowApplyOverlay = () => {
            if (this.main_ref) {
                this.main_ref.current.showBgrooviaApplyOverlay();
            }
        };

        onGenresChanged = (genres) => {
            this.setState({
                selectedGenres: genres,
            });
        };
        onPriceChanged = (min, max) => {
            this.setState({
                selectedPriceMin: min,
                selectedPriceMax: max,
            });
        };
        onListingStatesChanged = (listingStates) => {
            this.setState({
                selectedListingStates: listingStates,
            });
        };
        onSortByChanged = (sortBy) => {
            this.setState({
                selectedSortBy: sortBy,
            });
        };

        goToTrending = () => {
            window.scrollTo({
                top: this.trending_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToArtists = () => {
            window.scrollTo({
                top: this.artists_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToExplore = () => {
            window.scrollTo({
                top: this.explore_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        render() {
            return (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    darkMode={this.state.darkMode}
                    backgroundImage={bgroovia_background}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    {/* <div className={styles.gradient_background}>
                        <img src={gradient_background} />
                    </div> */}
                    <div className={styles.top_container}>
                        <ContentArea extraTopPadding={true}>
                            <div className={styles.header_section}>
                                <div className={styles.header_content}>
                                    <img src={bgroovia_text} className={styles.bgroovia_text_img} />
                                    <p>
                                        Delivering a Global Ecosystem
                                        <br />
                                        to Empower Artists.
                                    </p>
                                    <br />
                                    <Button displayMode={2} hoverMode={6} text={"Join B Groovia."} style={{ margin: 0 }} onClick={this.onShowApplyOverlay} />
                                </div>
                            </div>
                        </ContentArea>
                    </div>
                    <div className={styles.bottom_container}>
                        <ContentArea slim={true}>
                            <Statistics darkMode={this.state.darkMode} stats={{ artists: 3, music: 3, genres: 0 }} />
                            <br />
                        </ContentArea>

                        <span ref={this.trending_section_ref} />
                        <ContentArea darkMode={this.state.darkMode} header={"Trending."}>
                            <Banner windowSize={this.state.windowSize} darkMode={this.state.darkMode} genres={["music"]} schemes={["BGROOVIA"]} />
                            <br />
                        </ContentArea>

                        <span ref={this.artists_section_ref} />
                        <ContentArea darkMode={this.state.darkMode} header={"Artists."}>
                            <div className={styles.artists_container}>
                                {this.state.artists.map((profile, index) => (
                                    <div key={`artist_${index}`} className={styles.artist_container}>
                                        <Link to={`/u/${profile.displayName}`} className={styles.artist_image}>
                                            <img src={`${process.env.GATSBY_STORAGE}user/${profile.profileImage}`} />
                                        </Link>
                                        <p className={styles.artist_name}>{profile.alias}</p>
                                    </div>
                                ))}
                            </div>
                            <br />
                        </ContentArea>

                        <span ref={this.explore_section_ref} />
                        <ContentArea darkMode={this.state.darkMode} header={"Explore."}>
                            <FilterBar
                                darkMode={this.state.darkMode}
                                subGenre={"music"}
                                defaultGenres={this.state.selectedGenres}
                                defaultSortBy={this.state.selectedSortBy}
                                defaultListingStates={this.state.selectedListingStates}
                                onGenresChanged={this.onGenresChanged}
                                onPriceChanged={this.onPriceChanged}
                                onListingStateChanged={this.onListingStatesChanged}
                                onSortByChanged={this.onSortByChanged}
                            />
                            <br />
                            <MasonryTiles
                                contentEndpoint={"/content/filtered"}
                                contentEndpointParams={{
                                    schemes: JSON.stringify(["BGROOVIA"]),
                                    genres: JSON.stringify(this.state.selectedGenres),
                                    sortBy: this.state.selectedSortBy,
                                    includeCollections: true,
                                    listingStates: JSON.stringify(this.state.selectedListingStates),
                                    priceMin: this.state.selectedPriceMin,
                                    priceMax: this.state.selectedPriceMax,
                                }}
                                darkMode={this.state.darkMode}
                                windowSize={this.state.windowSize}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    </div>
                    <NavPanel
                        darkMode={true}
                        showScrollTop={true}
                        tabs={[
                            {
                                onClick: this.goToTrending,
                                text: "Trending",
                            },
                            {
                                onClick: this.goToArtists,
                                text: "Artists",
                            },
                            {
                                onClick: this.goToExplore,
                                text: "Explore",
                            },
                        ]}
                    />
                </Main>
            );
        }
    }
);
