// extracted by mini-css-extract-plugin
export var artist_container = "index-module--artist_container--VLzBi";
export var artist_image = "index-module--artist_image--9VZ9F";
export var artist_name = "index-module--artist_name--A+B4k";
export var artists_container = "index-module--artists_container--KLBqR";
export var bgroovia_text_img = "index-module--bgroovia_text_img--vTkTw";
export var bottom_container = "index-module--bottom_container--k19cm";
export var gradient_background = "index-module--gradient_background--vvIq6";
export var header_content = "index-module--header_content--c+fnL";
export var header_section = "index-module--header_section--idLKO";
export var top_container = "index-module--top_container--SA6De";